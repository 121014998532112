import reactlogo from "../../assets/reactlogo.png"
import CV from '../../assets/cv.pdf'
import { HiDownload } from 'react-icons/hi'
import data from './data'
import Card from '../../components/Card'
import './about.css'

const About = () => {
  return (
    <section id="about" data-aos="fade-in" dir="rtl">
        <div className="container about__container">
            <div className="about__left">
                <div className="about__portrait">
                    <img src={reactlogo} alt="React Logo" />
                </div>
            </div>
            <div className="about__right">
                <h2>REACT</h2>
                <div className="about__cards">
                    {
                        data.map(item => (
                            <Card key={item.id} className="about__card">
                                <span className='about__card-icon'>{item.icon}</span>
                                <h5>{item.title}</h5>
                                <small>{item.desc}</small>
                            </Card>
                        ))
                    }
                </div>
                <p>
                קורס  React  זהו הקורס האולטימטיבי למי שרוצה להיכנס לעולם הפיתוח המודרני וללמוד כיצד לבנות אפליקציות אינטראקטיביות ומתקדמות. במהלך הקורס נלמד כיצד להשתמש ב-React לפיתוח רכיבים מודולריים, ניהול מצבים באמצעות  useState ו-Redux , חיבור ל-API, בניית ממשקים דינאמיים ועוד.
                </p>
                <p>
                הקורס מתאים הן למתחילים שרוצים להתמקצע בפיתוח פרונט-אנד והן למפתחים מנוסים שמעוניינים לשדרג את יכולותיהם ולהשתמש בטכנולוגיות מודרניות. אנו נלמד איך להשתמש ב-Hooks, Context API, והכרת ספריות פופולריות כמו  React Router . הקורס מבוסס על  למידה מעשית , עם פרויקטים אמיתיים שיעזרו לך לצבור ניסיון ולבנות תיק עבודות מרשים.
                </p>
                <a href={CV} download className='btn primary'>להורדת סילבוס הקורס <HiDownload/></a>
            </div>
        </div>
    </section>
  )
}

export default About
