import Card from '../../components/Card'
import data from './data'
import './services.css'

const Services = () => {
  return (
    <section id="services" dir='rtl'>
      <h2>מה נלמד</h2>
      <p>
        
      בקורס זה נלמד את כל היסודות והכלים המתקדמים של  React  לפיתוח אפליקציות דינאמיות ומודרניות.  
      
      </p>
      <div className="container services__container" data-aos="fade-up">
        {
          data.map(item => (
            <Card key={item.id} className="service light">
              <div className="service__icon">{item.icon}</div>
              <div className="service__details">
                <h4>{item.title}</h4>
                  <p>{item.desc}</p>
              </div>
            </Card>
          ))
        }
      </div>
    </section>
  )
}

export default Services