import Image1 from '../../assets/project1.jpg'
import Image2 from '../../assets/project2.png'
import Image3 from '../../assets/project3.jpg'
import Image4 from '../../assets/project4.jpg'
import Image5 from '../../assets/project5.jpg'
import Image6 from '../../assets/project6.jpg'
import Image7 from '../../assets/project7.jpg'
import Image8 from '../../assets/project8.jpg'
import Image9 from '../../assets/project9.jpg'

const data = [
    {
        id: 1,
        category: 'useState',
        image: Image1,
        title: "ניהול State עם useState",
        desc: "פרויקט בו נלמד כיצד להשתמש ב-useState לניהול מצבים באפליקציה, נבנה טופס אינטראקטיבי עם עדכון ערכים בזמן אמת.",
        demo: 'http://egatortutorials.com',
        github: 'https://github.com/egattor'
    },
    {
        id: 2,
        category: 'useEffect',
        image: Image2,
        title: "שימוש ב-useEffect לעדכון נתונים",
        desc: "פרויקט שבו נטען מידע מ-API חיצוני באמצעות useEffect, ונלמד איך להתמודד עם שינויים בנתונים בזמן אמת.",
        demo: 'http://egatortutorials.com',
        github: 'https://github.com/egattor'
    },
    {
        id: 3,
        category: 'useContext',
        image: Image3,
        title: "ניהול נתונים עם useContext",
        desc: "פרויקט שבו נלמד כיצד להשתמש ב-useContext לשיתוף נתונים בין רכיבים מבלי להעביר props בצורה ישירה.",
        demo: 'http://egatortutorials.com',
        github: 'https://github.com/egattor'
    },
    {
        id: 4,
        category: 'useReducer',
        image: Image4,
        title: "ניהול State מתקדם עם useReducer",
        desc: "פרויקט בו נשתמש ב-useReducer כדי לנהל state מורכב בצורה נוחה, נלמד איך להשתמש בו במקום useState במקרים מסוימים.",
        demo: 'http://egatortutorials.com',
        github: 'https://github.com/egattor'
    },
    {
        id: 5,
        category: 'useRef',
        image: Image5,
        title: "שימוש ב-useRef לשליטה ברכיבים",
        desc: "נלמד כיצד להשתמש ב-useRef כדי לשמור reference לערכים מסוימים ולגשת לרכיבי DOM בלי לגרום לרינדור מחדש.",
        demo: 'http://egatortutorials.com',
        github: 'https://github.com/egattor'
    },
    {
        id: 6,
        category: 'useMemo',
        image: Image6,
        title: "שיפור ביצועים עם useMemo",
        desc: "פרויקט שבו נשתמש ב-useMemo כדי למנוע חישובים חוזרים ורינדור מיותר של רכיבים, ולהגביר את ביצועי האפליקציה.",
        demo: 'http://egatortutorials.com',
        github: 'https://github.com/egattor'
    },
    {
        id: 7,
        category: 'useCallback',
        image: Image7,
        title: "מניעת יצירת פונקציות מחדש עם useCallback",
        desc: "נלמד כיצד להשתמש ב-useCallback כדי למנוע יצירה מחודשת של פונקציות בתוך רכיבים ולהקטין רינדור מיותר.",
        demo: 'http://egatortutorials.com',
        github: 'https://github.com/egattor'
    },
    {
        id: 8,
        category: 'useLayoutEffect',
        image: Image8,
        title: "שימוש ב-useLayoutEffect לתפעול DOM",
        desc: "נלמד על useLayoutEffect ואיך הוא שונה מ-useEffect, כולל מצבים בהם כדאי להשתמש בו לשינויים ב-DOM.",
        demo: 'http://egatortutorials.com',
        github: 'https://github.com/egattor'
    },
    {
        id: 9,
        category: 'useId',
        image: Image9,
        title: "יצירת מזהים ייחודיים עם useId",
        desc: "פרויקט שמדגים איך להשתמש ב-useId ליצירת מזהים ייחודיים בטפסים ורכיבים דינאמיים.",
        demo: 'http://egatortutorials.com',
        github: 'https://github.com/egattor'
    }
]

export default data
