const data = [
  {
    id: 1,
    question: "האם אני צריך ידע מוקדם ב-JavaScript לפני הקורס?",
    answer: "כן, מומלץ להכיר את יסודות JavaScript כמו פונקציות, משתנים, לולאות ועבודה עם אובייקטים. עם זאת, בקורס ניתן סקירה קצרה על הנושאים החשובים ביותר."
  },
  {
    id: 2,
    question: "מה זה בעצם React ולמה כדאי ללמוד את זה?",
    answer: "React היא ספרייה פופולרית לפיתוח ממשקי משתמש אינטראקטיביים. היא מאפשרת בניית רכיבים מודולריים וחכמים, והיא אחת הטכנולוגיות המבוקשות ביותר בתעשייה."
  },
  {
    id: 3,
    question: "איך React מתמודד עם ביצועים ורינדור מיותר?",
    answer: "React משתמשת ב-Virtual DOM כדי לבצע רינדור יעיל יותר. ניתן גם לשפר ביצועים עם useMemo, useCallback, ו-React.memo כדי למנוע חישובים ורינדורים חוזרים."
  },
  {
    id: 4,
    question: "איך אפשר לנהל נתונים גלובליים באפליקציית React?",
    answer: "אפשר להשתמש ב-Context API או Redux כדי לשתף state בין רכיבים שונים, ללא צורך בהעברת props בצורה ידנית (prop drilling)."
  },
    {
      id: 5,
      question: "האם כדאי ללמוד Next.js בנוסף ל-React?",
      answer: "Next.js הוא פריימוורק מתקדם שמבוסס על React ומוסיף לו יכולות כמו טעינה מונעת שרת (SSR), יצירת דפים סטטיים (SSG), ניהול נתיבים חכם, ושיפור ביצועים. עם זאת, לפני שלומדים Next.js, חשוב להכיר היטב את React מכיוון שהוא נשען על הקונספטים המרכזיים של React, כמו: ניהול State עם useState ו-useReducer, שימוש ב-useEffect לטעינת נתונים, ניהול נתונים גלובליים עם Context API או Redux, יצירת רכיבים מודולריים ושימוש ב-JSX, והבנת ה-Virtual DOM ורינדור רכיבים בצורה אופטימלית. אם עדיין לא שלטת ב-React, המעבר ל-Next.js עלול להיות מורכב מדי, מכיוון שהוא מוסיף רמות חדשות של ניהול נתונים, ניווט מתקדם ורינדור דינאמי. לכן, ההמלצה היא ללמוד React תחילה, להבין היטב את היסודות שלו, ורק לאחר מכן להיכנס לעולם של Next.js. React היא הבסיס, Next.js הוא השדרוג!"
  },
  {
    id: 6,
    question: "איך עובדים עם טפסים ב-React?",
    answer: "אפשר להשתמש ב-state רגיל, או להשתמש בספריות כמו Formik יחד עם Yup לוולידציה מתקדמת של נתונים."
  },
  {
    id: 7,
    question: "איך ניתן לחבר את React ל-API חיצוני?",
    answer: "משתמשים ב-fetch API או בספריית Axios כדי לשלוח ולקבל נתונים משרתים חיצוניים. ניתן להשתמש ב-useEffect כדי לבצע את הקריאות בעת טעינת הרכיב."
  },
  {
    id: 8,
    question: "מה זה Custom Hooks ולמה להשתמש בהם?",
    answer: "Custom Hooks הם פונקציות מותאמות אישית שמאפשרות לנו לשמור על קוד נקי יותר על ידי מיחזור לוגיקה נפוצה בין רכיבים."
  },
  {
    id: 9,
    question: "מה ההבדל בין useState ל-useReducer?",
    answer: "useState משמש לניהול State פשוט בתוך רכיב, בעוד ש-useReducer מאפשר ניהול State מורכב יותר באמצעות פונקציית reducer, בדומה לניהול ב-Redux."
  },
  {
    id: 10,
    question: "מה ההבדל בין useEffect ל-useLayoutEffect?",
    answer: "useEffect מופעל לאחר שהרכיב נטען או ש-state השתנה, ואילו useLayoutEffect מופעל לפני שהדף מצויר למסך, מה שמאפשר לבצע עדכוני DOM קריטיים."
  }
]

export default data;
