import './portfolio.css'
import Projects from './Projects'
import ProjectsCategories from './ProjectsCategories'
import data from './data'
import { useState } from 'react'
import hooksInfo from './hooksInfo';

const Portfolio = () => {
  const [projects, setProjects] = useState(data);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const categories = data.map(item => item.category);
  const uniqueCategories = ["all", ...new Set(categories)];
  
  const filterProjectsHandler = (category) => {
    setSelectedCategory(category === "all" ? null : category);

    if(category === "all") {
      setProjects(data);
      return;
    }

    const filterProjects = data.filter(project => project.category === category);
    setProjects(filterProjects);
  }
  

  return (
    <section id="portfolio" dir='rtl'>
      <h2>פרויקטים לפי Hooks</h2>
      <p>כאן תוכלו לראות פרויקטים שונים שמיישמים את ה-Hooks המרכזיים של React.</p>


      <div className="container portfolio__container">
        <ProjectsCategories categories={uniqueCategories} onFilterProjects={filterProjectsHandler}/>

          {/* הצגת ההסבר המתאים לקטגוריה שנבחרה */}
          {selectedCategory && hooksInfo[selectedCategory] && (
          <div className="category-info">
            <h3 dir='rtl'>{selectedCategory}</h3>
            <p dir='rtl'>{hooksInfo[selectedCategory]}</p>
          </div>
        )}
        

        <Projects projects={projects}/>
      </div>
    </section>
  )
}

export default Portfolio