import {FaAward} from 'react-icons/fa'
import {TbBooks} from 'react-icons/tb'
import {BiHappyHeartEyes} from 'react-icons/bi'


const data = [
    {id: 1, icon: <FaAward/>, title: 'ניסיון', desc: 'רמת קושי מדרגות מל למורכב'},
    {id: 2, icon: <TbBooks/>, title: 'פרויקטים', desc: 'בכל תת נושא בשפה'},
    {id: 3, icon: <BiHappyHeartEyes/>, title: 'הספק', desc: 'נתחיל מפרויקט לשיעור ונעלה את הכמות'}
    ]



    export default data;