import {AiOutlineInstagram} from 'react-icons/ai'
import {AiOutlineTwitter} from 'react-icons/ai'
import {AiOutlineDribbble} from 'react-icons/ai'
import {AiFillGithub} from 'react-icons/ai'

export const links = [
    {id: 1, link: '#', title: 'React'},
    {id: 2, link: '#about', title: 'על הקורס'},
    {id: 3, link: '#services', title: 'סילבוס'},
    {id: 4, link: '#portfolio', title: 'פרויקטים'},
 
]


export const socials = [
    {id: 1, link: 'https://instagram.com', icon: <AiOutlineInstagram/>},
    // {id: 2, link: 'https://twitter.com', icon: <AiOutlineTwitter/>},
    // {id: 3, link: 'https://dribbble.com', icon: <AiOutlineDribbble/>},
    {id: 4, link: 'https://github.com', icon: <AiFillGithub/>}
]