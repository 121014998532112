import { useEffect } from 'react'
import faqs from './data'
import FAQ from './FAQ'
import AOS from 'aos'
import 'aos/dist/aos.css'
import './faqs.css'

const FAQs = () => {
  useEffect(() => {
    AOS.init({duration: 2000})
  })

  return (
    <section id="faqs" dir='rtl'>
     <h2>שאלות נפוצות על קורס React</h2>
      <p>
        כאן תמצאו תשובות לשאלות נפוצות על הקורס. אם יש לכם שאלות נוספות, 
        אל תהססו ליצור קשר ואשמח לעזור לכם להבין איך הקורס יכול לקדם אתכם בעולם ה-Front-end!
      </p>   <div className="container faqs__container" data-aos="fade-in">
        {
          faqs.map(faq => (
            <FAQ key={faq.id} faq={faq}/>
          ))
        }
      </div>
    </section>
  )
}

export default FAQs