const hooksInfo = {
    useState: "ה-Hook useState מאפשר לנו לשמור ולשנות ערכים בתוך state מקומי בתוך רכיב React. הוא מחליף משתנים רגילים על ידי שמירה על הנתונים גם אחרי רינדור מחדש.",
    useEffect: "ה-Hook useEffect מאפשר להריץ קוד בכל פעם שהרכיב נטען או שה-State משתנה. משמש לאינטראקציות עם API, טיימרים ואירועי צד.",
    useContext: "ה-Hook useContext משמש לשיתוף נתונים בין רכיבים שונים בלי צורך בהעברת props. הוא חוסך prop drilling ומאפשר ניהול גלובלי נוח יותר.",
    useReducer: "ה-Hook useReducer משמש לניהול State מורכב יותר בצורה דומה ל-Redux. הוא עובד עם reducer function לטיפול בעדכוני state בצורה מבוקרת.",
    useRef: "ה-Hook useRef מאפשר לנו לשמור reference לערכים מסוימים מבלי לגרום לרינדור מחדש, וגם לגשת לרכיבי DOM ישירות.",
    useMemo: "ה-Hook useMemo משמש להאצת ביצועים על ידי שמירה בזיכרון של חישובים יקרים והימנעות מרינדור חוזר.",
    useCallback: "ה-Hook useCallback משמש למניעת יצירת פונקציות חדשות בכל רינדור, מה שמאפשר שיפור ביצועים במקרה של העברת פונקציות כתלות.",
    useLayoutEffect: "ה-Hook useLayoutEffect דומה ל-useEffect אך פועל לפני שהדף נצבע למסך. מתאים למצבים בהם נדרשים שינויים ב-DOM לפני הצגת הרכיב.",
    useId: "ה-Hook useId משמש ליצירת מזהים ייחודיים באופן דינאמי, מה שמונע התנגשויות בין מזהים בטפסים ורכיבים חוזרים."
}

export default hooksInfo;
