import { RiReactjsLine, RiRouterFill } from 'react-icons/ri'
import { AiOutlineApi, AiFillAppstore } from 'react-icons/ai'
import { FaServer, FaCodeBranch } from 'react-icons/fa'
import { BsGearFill } from 'react-icons/bs'

const data = [
    {
        id: 1, 
        icon: <RiReactjsLine />, 
        title: 'יסודות React', 
        desc: "נלמד כיצד פועלת ספריית React, איך ליצור רכיבים, כיצד להשתמש ב-state וב-props, ולבנות ממשקים דינאמיים באמצעות JSX."
    },
    {
        id: 2, 
        icon: <RiRouterFill />, 
        title: 'ניהול ניווט עם React Router', 
        desc: "נלמד כיצד לבנות אפליקציה מרובת דפים עם React Router, לנווט בין עמודים בצורה חכמה, להשתמש בפרמטרים בכתובת URL ולשפר את חוויית המשתמש."
    },
    {
        id: 3, 
        icon: <AiOutlineApi />, 
        title: 'עבודה עם API וחיבור לשרתים', 
        desc: "נלמד איך לשלוח ולקבל נתונים משרתים חיצוניים באמצעות Fetch ו-Axios, נלמד על עבודה עם RESTful API, ונתנסה בהצגת מידע דינאמי באפליקציה."
    },
    {
        id: 4, 
        icon: <FaCodeBranch />, 
        title: 'ניהול State מתקדם (Hooks, Redux)', 
        desc: "נבין איך להשתמש ב-Hooks כמו useState ו-useEffect, איך ניהול ה-state מתבצע באמצעות Context API, ואיך Redux עוזר לנו לארגן את הנתונים באפליקציה מורכבת."
    },
    {
        id: 5, 
        icon: <BsGearFill />, 
        title: 'בניית אפליקציות React בקוד נקי', 
        desc: "נלמד כיצד לכתוב קוד נקי ויעיל ב-React, להשתמש בארגון נכון של קבצים, כתיבה נכונה של פונקציות, והפרדה בין רכיבים לצורך תחזוקה קלה יותר."
    }
]

export default data
