import React from "react";
import { ReactComponent as Keyboard } from "./svg/keyboard.svg";
import { ReactComponent as Code } from "./svg/code.svg";
import { ReactComponent as JS } from "./svg/js.svg";
import { ReactComponent as ReactSVG } from "./svg/react.svg";
import { ReactComponent as Git } from "./svg/git.svg";
import { ReactComponent as Html } from "./svg/html.svg";
import { ReactComponent as Developer } from "./svg/developer.svg";
import { ReactComponent as Time } from "./svg/time.svg";
import { ReactComponent as Develop } from "./svg/develop.svg";
import { ReactComponent as Web } from "./svg/web.svg";
import { ReactComponent as Home } from "./svg/home.svg";
import { ReactComponent as Heart } from "./svg/heart.svg";
import { ReactComponent as Cloud } from "./svg/cloud.svg";

import "./AnimatedBackground.css";

function AnimatedBackground({ children }) {
  let arr = [];
  let qty = 15;

  for (let i = 0; i < qty; i++) {
    arr.push(i);
  }

  const layers = arr.map((i) => {
    return (
      <div key={i} className="animated-row">
        <div>
          <Code />
          <JS />
          <ReactSVG />
          <Keyboard />
          <Git />
         
     <Develop/>
          <Html />
<Developer/>
          <Time />      
          <Home />
          <Heart />
          <Web />
          <Cloud />
    
        </div>

        <div>
          <Code />
          <JS />
          <ReactSVG />
          <Keyboard />
          <Git />
         
          <Develop/>
          <Html />
          <Developer/>
          <Time />
          <Home />    
          <Heart />    
          <Web />     
          <Cloud />
    
        </div>
      </div>
    );
  });

  return (
    <section className="animated-section">
      {layers}
      {children}
    </section>
  );
}

export default AnimatedBackground;
